import request from '../../request'

export function fetchList(params) {
    return request({
        url: '/office-service/dine/car/temp/list',
        params,
    })
}

export function add(data) {
    return request({
        url: '/office-service/dine/car/temp/add',
        data,
        method: "post",
    })
}
export function received(data) {
    return request({
        url: '/office-service/dine/car/temp/received',
        data,
        method: "post",
    })
}

export function returned(data) {
    return request({
        url: '/office-service/dine/car/temp/returned',
        data,
        method: "post",
    })
}