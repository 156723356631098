<template>
    <div>
        <Pane />
  
      <a-card class="container">
  
        <a-form
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 12 }"
            :colon="false"
            @submit="handleSubmit"
            :form="form"
        >
          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="申请人">
              <a-button block style="text-align: left">
                {{
                user.userName
                }}
              </a-button>
            </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="申请部门">
                <a-button block style="text-align: left">
                    {{
                    department.deptUniqueName
                    }}
                </a-button>
                </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="预计用卡时间">
                <a-date-picker v-decorator="[
                    'useDate',
                    { rules: [{ required: true, message: '请选择！' }] },
                    ]" style="width: 420px" />
                </a-form-item>
            </a-col>

            <a-col :lg="12" :md="12" :sm="24">
                <a-form-item label="职能部门">
                    <a-select
                        v-decorator="[
                            'functionDeptId',
                            { 
                                rules: [{ required: true, message: '请选择！' }] },
                        ]"
                        >
                        <a-select-option
                            v-for="item in organizationList"
                            :key="item.id"
                            :value="item.id"
                            >{{ item.uniqueName }}</a-select-option
                        >
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :lg="24" :pull="4" :md="24" :sm="24">
            <a-form-item label="申请项目或事由">
              <a-textarea
                :auto-size="{ minRows: 3, maxRows: 5 }"
                v-decorator="[
                  'content',
                  {
                    rules: [
                      {
                        required: true,
                        message: '请输入！',
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
  
          </a-row>
  
          <div class="footer">
            <a-space>
              <a-button type="primary" html-type="submit" :loading="saveLoading">提交</a-button>
              <a-button @click="$close($route.path)">关闭</a-button>
            </a-space>
          </div>
        </a-form>
      </a-card>
    </div>
  </template>
  
  <script>
import organization from "@/mixins/organization";
import {add} from "@/api/oa/meal-card";
import moment from "moment";
  import { mapGetters, mapState } from "vuex";
  export default {
    data() {
      return {
        form: this.$form.createForm(this),
        saveLoading: false,
      };
    },
    mixins: [organization],
    computed: {
        ...mapState("auth", ["user"]),
        ...mapGetters("auth", ["department"]),
    },
    mounted() {
      
    },
    methods: {
          handleSubmit(e) {
            e.preventDefault();
            console.log(this.department)
            const that = this;
              this.form.validateFields((err, values) => {
                  if (!err) {
                    that.$confirm({
                      title: "本次提交将发起审批流程，是否继续？",
                      onOk() {
                        that.saveLoading = true;
                        const json = {
                          applicant: that.user.userName,
                          applicantId: that.department.userId,
                          applicantDeptId: that.department.deptId,
                          applicantDept: that.department.deptUniqueName,
                          applyTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                          useDate: values.useDate.format('YYYY-MM-DD'),
                          content: values.content,
                          functionDept: that.organizationList.filter(v=>v.id == values.functionDeptId )[0].uniqueName,
                          functionDeptId: values.functionDeptId
                        }
                        add(json).then(() => {
                          that.$close(that.$route.path);
                        }).finally(()=>{
                          that.saveLoading = false;
                        }) 
                      }
                    });
                      
                  }
              });
          }
      }
  };
  </script>
  
  <style lang="less" scoped>
  .pane {
    background-color: #fff;
    padding: 16px 32px;
    position: relative;
  
    .title {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
    }
  
    .remarks {
      margin-top: 8px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  
  .footer {
    padding: 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>